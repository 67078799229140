/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import PortalLayout from '../../../../layouts/PortalLayout';
import { Loader, Message, SectionBox } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  responseSelector,
  hasErrorSelector,
  isBusySelector,
  getAncestryThunk,
  hasSuccessSelector,
  hasPendingSelector,
} from '../../../../store/ancestry';
import AncestryMaps from './components/AncestryMaps';
import Grid from '@mui/material/Grid';
import AncestryList from './components/AncestryList';
import AncestryGraph from './components/AncestryGraph';

function AncestryScene() {
  const { t } = useTranslation('portal');

  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const hasError = useAppSelector(hasErrorSelector);
  const response = useAppSelector(responseSelector);
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const hasPending = useAppSelector(hasPendingSelector);

  useEffect(() => {
    if (!response) {
      dispatch(getAncestryThunk());
    }
  }, [dispatch, response]);

  return (
    <PortalLayout>
      <SectionBox>
        <Typography variant="h5" component="h1" paragraph>
          {t('ancestry.title')}
        </Typography>

        {hasError ||
          (hasPending && (
            <Message severity="error" title={`${t('alerts.AncestryFailed.title')}`}>
              {t('alerts.AncestryFailed.text')}
            </Message>
          ))}

        {response && hasSuccess && (
          <>
            <p>{t('ancestry.descriptionParagraph1')}</p>
            <p>{t('ancestry.descriptionParagraph2')}</p>
            <Grid container mt={3}>
              <Grid item xs={12} sm={6} pl={{ sm: 1 }} pr={{ md: 5 }}>
                <AncestryList ancestryData={response} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AncestryGraph ancestryData={response} />
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item xs={12}>
                <AncestryMaps ancestryData={response} />
              </Grid>
            </Grid>
          </>
        )}
      </SectionBox>
      <Loader isVisible={isBusy} label={t('loaders.waiting') as string} />
    </PortalLayout>
  );
}

export default AncestryScene;
