import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { Preferences } from '../../core/api/portal.types';

export const hasErrorSelector = (state: RootState): boolean =>
  state.preferences.errors && state.preferences.errors ? true : false;

export const isBusySelector = (state: RootState): boolean => state.preferences.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Preferences => state.preferences.response || null;

export const hasSuccessSelector = (state: RootState): any | null => (state.preferences.response ? true : false);

export const hasCheckedSelector = (state: RootState): boolean => state.preferences.hasChecked;

export const hasUpdatedSelector = (state: RootState): boolean => state.preferences.hasUpdated;

export const ehrOptinSelector = (state: RootState): Preferences =>
  state.preferences.response ? state.preferences.response.ehrOptin : false;

export const ancestryOptinSelector = (state: RootState): Preferences =>
  state.preferences.response ? state.preferences.response.ancestryOptin : false;
